<template>
  <div class="row m-0 w-100 cursor">
    <div class="col-12 p-0 text-center">
      <div class="row m-0 w-100 justify-content-center">
        <div class="col-auto px-0">
          <div class="icon_holder" :style="{'background-color': bg}">
            <div class="row m-0 h-100 align-items-center justify-content-center text-center roundicon">
              <slot></slot>
            </div>
          </div>
        </div>
      </div>
      <div class="icon_text text-center" v-if="name">
        <div class="row mx-0 align-items-center justify-content-center">
          <div class="col-auto p-0">
            {{name}}
          </div>
          <div class="col-auto p-0 h-100">
            <ios-arrow-down-icon class="inline-icon inherit" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    'ios-arrow-down-icon': defineAsyncComponent(() => import('vue-ionicons/dist/ios-arrow-down.vue'))
  },
  name: 'SwitchLocationMobile',
  props: ['name', 'bg']

}
</script>

<style scoped>
  .icon_holder {
    background-color: transparent;
    border-radius: 50%;
    width: 26px;
    height: 26px;
    color: var(--gold-color);
    text-align: center;
  }
  .icon_text {
    font-weight: bold;
    font-size: 8px;
    color: var(--gold-color);
  }
  @media (min-width: 992px) {
    .icon_holder {
      background-color: var(--green-color-dark);
    }
    .icon_holder {
      background-color: var(--green-color-dark);
      border-radius: 50%;
      width: 45px;
      height: 45px;
      font-size: 21px;
      color: var(--gold-color);
      text-align: center;
    }
    .icon_holder:hover {
      background-color: var(--green-color) !important;
    }
    .icon_text {
      font-weight: bold;
      font-size: 12px;
      color: var(--gold-color);
    }
  }
</style>
